import React from 'react';
import { Card, Image } from '../assets';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { generateAddress } from '../util/helperFunctions';

const Accordion = ({ data, isOpen, setIsOpen }) => {
 const navigate = useNavigate();

 const navigateToPyment = (citationNumber) => {
  navigate(`/pay-citation?citation-number=${citationNumber}`);
 };
 return (
  <div>
   <div
    className={`flex flex-col gap-3 md:gap-0 md:flex-row md:flex-wrap justify-between md:items-center px-4 py-2 cursor-pointer ${
     isOpen === data?.id ? ' bg-black bg-opacity-5' : 'bg-white bg-bg-opacity-5'
    } hover:bg-black hover:bg-opacity-5`}
    onClick={() => {
     if (data?.id === isOpen) {
      setIsOpen(null);
     } else {
      setIsOpen(data?.id);
     }
    }}
   >
    <div className="flex justify-between md:hidden items-baseline">
     <p className="text-sm font-bold text-black text-opacity-50">Date: </p>
     <p className="w-[130.63px] break-words text-sm text-black ">
      {data?.date || '---'}
     </p>
    </div>
    <p className="w-[130.63px] break-words hidden md:block text-sm text-black ">
     {data?.date || '---'}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Vehicle: </p>
     <p className="w-[130.63px] break-words block md:hidden text-sm text-black">
      {data?.color || data?.make || data?.modal
       ? `${data?.color || ''} ${data?.make || ''} ${data?.modal || ''}`
       : '---'}
     </p>
    </div>
    <p className="w-[167px] break-words hidden md:block">
     {data?.color || data?.make || data?.modal
      ? `${data?.color || ''} ${data?.make || ''} ${data?.modal || ''}`
      : '---'}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Violation: </p>
     <p className="w-[130.63px] break-words text-sm text-black">
      {data?.violation?.title}
     </p>
    </div>
    <p className="w-[124px] break-words hidden md:block text-sm text-black">
     {data?.violation?.title}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Status: </p>
     <p
      className={`w-[130.63px] break-words font-bold text-sm ${
       data?.citationStatus === 'PENDING' ? 'text-[#F75474]' : 'text-green-400'
      }`}
     >
      {data?.citationStatus === 'PENDING' ? 'Not Paid' : data?.citationStatus}
     </p>
    </div>
    <p
     className={`w-[90px] break-words hidden font-bold md:block text-sm  ${
      data?.citationStatus === 'PENDING' ? 'text-[#F75474]' : 'text-green-400'
     }`}
    >
     {data?.citationStatus === 'PENDING' ? 'Not Paid' : data?.citationStatus}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Owed: </p>
     <p className="w-[130.63px] break-words text-sm text-black">
      {data?.fineAmount}
     </p>
    </div>
    <p className="w-[48px] break-words hidden md:block text-sm text-black">
     {data?.fineAmount}
    </p>
   </div>
   {isOpen === data?.id && (
    <div className="bg-gray09 flex flex-1 pt-[10px]">
     <div className="flex flex-col flex-1 gap-2">
      <div className="flex flex-col md:flex-row md:flex-1 justify-between items-stretch gap-2 px-2">
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] px-[22px] md:pl-[22px]">
        <p className="text-xs font-semibold">Address of Violation:</p>
        <p className="text-xs text-black text-opacity-70">
         {data?.location?.address || generateAddress(data?.locationResult)}
        </p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">Description:</p>
        <p className="text-xs text-black text-opacity-70">
         {data?.description || '---'}
        </p>
       </div>
      </div>
      <div className="flex flex-col md:flex-row md:flex-1 justify-between items-stretch gap-2 px-2">
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] px-[22px] md:pl-[22px]">
        <p className="text-xs font-semibold">Citation #:</p>
        <p className="text-xs text-black text-opacity-70">
         {data?.citationNumber}
        </p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">License Plate:</p>
        <p className="text-xs text-black text-opacity-70">{`${data?.licensePlateNumber} ${data?.licensePlateState}`}</p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">Evidence:</p>
        {data?.photo ? (
         <a href={data?.photo} target="_blank" rel="noopener noreferrer">
          <div className="flex justify-start items-center gap-2">
           <Image />
           <p className="text-xs text-black text-opacity-70">evidence.png</p>
          </div>
         </a>
        ) : (
         '---'
        )}
       </div>
      </div>
      {data?.citationStatus === 'PENDING' && (
       <div className="flex flex-col px-2 md:px-0 md:flex-row  md:justify-end items-center gap-2 pb-[6px] text-nowrap  ">
        <Button
         name={'Contest Citation'}
         customClass=" !tracking-[2px] w-full md:w-auto"
         onClick={() =>
          navigate(`/contest-citation?citation-number=${data?.citationNumber}`)
         }
         size="small"
         variant="secondary"
        />
        <Button
         name={'Pay My Ticket'}
         customClass=" !tracking-[2px] md:mr-2 w-full md:w-auto"
         onClick={() => navigateToPyment(data?.citationNumber)}
         size="small"
         variant="primary"
         svg={<Card />}
         svgBefore={true}
        />
       </div>
      )}
     </div>
    </div>
   )}
  </div>
 );
};

export default Accordion;
