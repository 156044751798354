import React, { useCallback, useEffect, useState } from 'react';
import VehicleDetailCard from '../components/VehicleDetailCard';
import LogoWithAddress from '../components/LogoWithAddress';
import { Back, Car, Citation } from '../assets';
import Accordion from '../components/Accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import useApiStore from '../store/store';
import { gql, useLazyQuery } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
export const GET_CITATION_PERMITS = gql`
 query GetCitationPermits(
  $citationNumber: Float
  $licensePlateState: String
  $licensePlateNumber: String
 ) {
  getCitationPermits(
   getCitationPermitInput: {
    citationNumber: $citationNumber
    licensePlateState: $licensePlateState
    licensePlateNumber: $licensePlateNumber
   }
  ) {
   vehicleData
   citations {
    id
    color
    description
    licensePlateNumber
    licensePlateState
    make
    modal
    photo
    date
    time
    fineAmount
    location
    locationResult
    citationNumber
    citationStatus
    userId
    violationId
    violation {
     code
     title
    }
    municipalityId
    municipality {
     id
     name
     logo
    }
    createdAt
    updatedAt
   }
   permits {
    id
    permitNumber
    color
    licensePlateNumber
    licensePlateState
    make
    modal
    fee
    ownerCity
    ownerAddress
    ownerName
    term
    ownerZip
    ownerState
    ownerDOB
    effectiveDate
    expirationDate
    permitStatus
    municipalityId
    municipality {
     id
     name
     logo
    }
    parkingPermitId
    createdAt
    updatedAt
   }
  }
 }
`;
const TicketDetails = () => {
 const { setData, setMunicipality, setSearchUrl } = useApiStore();
 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);
 const citationNumber = queryParams.get('citation-number');
 const state = queryParams.get('state');
 const licensePlate = queryParams.get('license-plate');
 const [apiData, setApiData] = useState();
 const [loading, setLoading] = useState();
 const [isOpen, setIsOpen] = useState(null);
 const [getCitationPermits] = useLazyQuery(GET_CITATION_PERMITS, {
  fetchPolicy: 'network-only',
 });
 const handleSearchData = useCallback(async () => {
  try {
   setLoading(true);
   const { data } = await getCitationPermits({
    variables: {
     citationNumber: Number(citationNumber) || null,
     licensePlateState: state || null,
     licensePlateNumber: licensePlate || null,
    },
   });

   const searchUrl = `${location.pathname}${location.search}`;
   localStorage.setItem('searchUrl', searchUrl);
   setSearchUrl(searchUrl);
   setData(data?.getCitationPermits);
   setApiData(data?.getCitationPermits);

   if (data?.getCitationPermits?.citations?.[0]) {
    setMunicipality(data?.getCitationPermits?.citations?.[0]?.municipality);
   } else if (data?.getCitationPermits?.permits?.[0]) {
    setMunicipality(data?.getCitationPermits?.permits?.[0]?.municipality);
   }
  } catch (error) {
   console.log('>>>', error);
  } finally {
   setLoading(false);
  }
 }, [
  citationNumber,
  getCitationPermits,
  licensePlate,
  location.pathname,
  location.search,
  setData,
  setMunicipality,
  setSearchUrl,
  state,
 ]);
 useEffect(() => {
  handleSearchData();
 }, [state, licensePlate, citationNumber, handleSearchData]);

 const navigate = useNavigate();
 return loading ? (
  <div className="flex flex- h-screen justify-center items-center">
   <ClipLoader />
  </div>
 ) : apiData?.citations.length > 0 ? (
  <>
   <div className="flex flex-col justify-start items-center h-[calc(100vh-121px)]">
    <LogoWithAddress />
    <div className="md:w-[704px] pb-11">
     {/* <div className="flex  justify-start items-center gap-2 pb-[18px]">
      <Car />
      <p className="text-lg font-medium">Vehicles</p>
     </div>
     <div className="flex  flex-col items-center md:flex-row md:items-baseline md:flex-wrap justify-between gap-4 pb-[32px]">
      {[1, 2, 3, 4].map((data, idx) => {
       return <VehicleDetailCard isPaid={idx !== 2} />;
      })}
     </div> */}
     <div className="border-b"></div>
     <div className="flex  justify-start items-center gap-2 pb-[18px] pt-[32px]">
      <Citation />
      <p className="text-lg font-medium">Citations</p>
     </div>
     <div className="border">
      <div className=" hidden md:flex justify-between items-center bg-black bg-opacity-5 px-4 py-2 text-wrap">
       <p className="w-[130.63px] text-sm font-bold text-black text-opacity-50">
        Date/Time
       </p>
       <p className="w-[167px] text-sm font-bold text-black text-opacity-50">
        Vehicle
       </p>
       <p className="w-[124px] text-sm font-bold text-black text-opacity-50">
        Violation
       </p>
       <p className="w-[90px] text-sm font-bold text-black text-opacity-50">
        Status
       </p>
       <p className="w-[48px] text-sm font-bold text-black text-opacity-50">
        Owed
       </p>
      </div>
      {apiData?.citations?.map((item) => (
       <Accordion
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        key={item.id}
        data={item}
        content={'item.details'}
       />
      ))}
     </div>
    </div>
   </div>
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate('/')}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
  </>
 ) : (
  <div className="h-[calc(100vh-121px)] flex flex-1 justify-center items-center">
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate('/')}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
   No Data found
  </div>
 );
};

export default TicketDetails;
