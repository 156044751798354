import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

// Create the upload link
const uploadLink = createUploadLink({
 //  uri: 'http://ec2-54-167-65-64.compute-1.amazonaws.com:3000/graphql',
 uri: 'https://api.enforceapp.com/graphql',
});

const authLink = setContext((_, { headers }) => {
 return {
  headers: {
   ...headers,
   'x-apollo-operation-name': 'your-operation-name',
   'apollo-require-preflight': 'true',
   'Content-Type': 'application/json',
  },
 };
});

const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
 link,
 cache: new InMemoryCache(),
});

export default client;
