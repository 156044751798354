import { create } from 'zustand';

const useApiStore = create((set) => ({
 searchData: null,
 municipality: null,
 activeCitation: null,
 searchUrl: null,
 setSearchUrl: (data) => set({ searchUrl: data }),
 setMunicipality: (data) => set({ municipality: data }),
 setActiveCitation: (data) => set({ activeCitation: data }),
 setData: (newData) => set({ searchData: newData }),
 clearData: () => set({ searchData: null }),
}));

export default useApiStore;
