import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import SearchTicket from '../pages/SearchTicket';
import TicketDetails from '../pages/TicketDetails';
import ContestCitation from '../pages/ContestCitation';
import PayCitation from '../pages/PayCitation';
import ContactUs from '../pages/ContactUs';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentError from '../pages/PaymentError';
import CancelPage from '../pages/CancelPage';

const Router = () => {
 return (
  <Routes>
   <Route path="/" element={<MainLayout />}>
    <Route index element={<SearchTicket />} />
    <Route path="search-ticket" element={<SearchTicket />} />
    <Route path="contact-us" element={<ContactUs />} />
    <Route path="*" element={<Navigate to="/" />} />
    <Route path="ticket" element={<TicketDetails />} />
    <Route path="contest-citation" element={<ContestCitation />} />
    <Route path="pay-citation" element={<PayCitation />} />
    <Route path="success" element={<PaymentSuccess />} />
    <Route path="error" element={<PaymentError />} />
    <Route path="cancel" element={<CancelPage />} />
    <Route path=":municipalityName" element={<SearchTicket />} />
   </Route>
  </Routes>
 );
};

export default Router;
