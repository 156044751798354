import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useApiStore from '../store/store';

function CancelPage() {
 const navigate = useNavigate();
 const location = useLocation();
 const { searchUrl } = useApiStore();

 useEffect(() => {
  if (searchUrl) {
   console.log('<><><><<><>', searchUrl);
   navigate(searchUrl, { replace: true });
  } else {
   navigate('/', { replace: true });
  }
 }, [navigate, location, searchUrl]);

 return null;
}

export default CancelPage;
